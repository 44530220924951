.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    background-color: rgba(89, 4, 236, 0.918);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
  }
  
  .navbar .navbar-toggler {
    top: .25rem;
    right: 1rem;
  }
  
  .navbar .form-control {
    padding: .75rem 1rem;
  }
  
  .form-control-dark {
    color: #fff;
    background-color: rgba(8, 12, 244, 0.921);
    border-color: rgba(91, 21, 243, 0.952);
  }
  
  .form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
  }