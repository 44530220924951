.about__project {
    color: DodgerBlue;
    font-size: 40px;
    text-align: center;
    padding-top: 85px;
}

.project__description {
    color:black;
    padding-top: 20px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
}

.title {
    color: red;
    font-size: 20px;
    text-align: center;
    padding-top: 10px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 20px;
}
